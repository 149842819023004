.header-container {
  position: relative;
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  .login-button {
    position: absolute;
    right: 15px;
  }

  .signup-button {
    position: absolute;
    right: 105px;
  }

  .header-container-inner {
    position: relative;
    margin: 0 auto;
    height: 80px;
    width: 95%;
    max-width: 1400px;
    display: flex;
    align-items: center;
  }

  .org-icon-container {
    position: absolute;
    height: 75%;
    width: 180px;
    left: 0px;
    background-image: url('../../assets/badge.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    cursor: pointer;

    @media (max-width: 900px) {
      height: 75%;
      width: 140px;
    }
  }

  .longjump-primary-logo {
    position: absolute;
    background-image: url('../../assets/loading-blue.png');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    width: 50px;
    height: 50px;
    animation: rotateSpinner 2s linear infinite;
  }
  
  @keyframes rotateSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .header-navigation-mobile-toggle {
    position: relative;
    left: 0px;
    top: 3px;
    height: 75%;
    width: 50px;
    background-image: url('../../assets/menu-icon-blue.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    cursor: pointer;
  }

  .header-navigation-container {
    position: relative;
    width: 350px;
    height: 65%;
    background-color: #0090F2;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    left: 22px;

    @media (max-width: 900px) {
      display: none;
    }

    a {
      color: #fff;
      text-decoration: unset;
      padding: 10px 20px;
      margin: 0px 10px;

      &:hover {
        text-decoration: underline;
      }
    }

    .active-link {
      background-color: #fff;
      border-radius: 30px;
      color: #0090F2;
      font-weight: 500;
    }
  }

  .user-container {
    position: absolute;
    display: flex;
    align-items: center;
    width: 200px;
    height: 100%;
    right: 0px;

    b {
      position: relative;
      font-size: 14.5px;
      font-weight: 600;
      right: 94px;
      text-align: right;
      text-wrap: nowrap;
      width: 100%;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .user-settings {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;

      button {
        position: absolute;
        width: 80px;
        right: 70px;
        margin: 0;
        margin-top: 30px;
      }
    }

    .profile-picture-container {
      position: absolute;
      width: 75px;
      height: 50px;
      border-radius: 30px;
      background-color: #DADADA;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      right: 1px;
      margin-top: -2px;
      cursor: pointer;
      border: 1px solid #D0D0D0;

      &:hover {
        background-color: #000;
      }
    }

    .hover-arrow {
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 30px;
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
      background-image: url('../../assets/chevron-white.png');
      right: 5px;
      top: 15px;
    }

    .view-profile-click-area {
      position: absolute;
      width: 100px;
      height: 60px;
      top: 0px;
      left: 0px;
    }

    .profile-picture {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #c9c9c9;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      left: 4px;
      top: 5px;

      .hover-card {
        display: none;
        position: absolute;
        top: 45px;
        right: -32px;
        width: 180px;
        height: 220px;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #D0D0D0;
        padding: 20px;
        border-radius: 12px;
        z-index: 3;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        flex-direction: column;

        h3 {
          margin: 5px 15px;
          margin-left: -10px;
          margin-bottom: 0px;
          font-size: 14px;
          text-wrap: nowrap;
        }

        ul {
          margin: 0px 20px;
          margin-top: 15px;
          padding: 0px;
          font-size: 14px;
          width: 75%;
          list-style-type: none;
          li {
            padding: 10px 8px;
            font-weight: 600;
            color: #0090F2;
            cursor: pointer;
            border-radius: 8px;
          }
          li:hover {
            background-color: #EFEFEF;
          }
        }

        .app-nav {
          position: relative;
          width: 90%;
          height: auto;
          border-top: 1px solid #ddd;
          margin-top: 15px;
          padding-top: 10px;

          .app-nav-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 40px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            color: #000;
            background-color: #F9F9F9;
            .app-nav-icon {
              display: inline-block;
              width: 30px;
              height: 30px;
              margin-right: 8px;
              margin-left: 25px;
              background-repeat: no-repeat;
              background-size: 70%;
              background-position: center;
            }
            .practice-icon {
              background-image: url('../../assets/camera-blue.svg');
            }
            .compete-icon {
              background-image: url('../../assets/trophy-blue.svg');
            }
            &:hover {
              background-color: #EFEFEF;
            }
          }
          .practice-button, .compete-button {
            color: #0090F2;
            margin-top: 5px;
            background-color: #fff;
            &:hover {
              background-color: #EFEFEF;
              // .practice-icon {
              //   background-image: url('../../assets/camera-white.svg');
              // }
              // .compete-icon {
              //   background-image: url('../../assets/trophy-icon.svg');
              // }
            }
          }
        }
      }
    }
    
    .profile-picture-container:hover .hover-card {
      display: flex;
    }
  }
}

.header-navigation-mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #eee;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header-navigation-mobile-menu-options {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 200px;

    a {
      text-decoration: unset;
      font-size: 20px;
      color: #0090F2;
      font-weight: 500;
      background-color: #F9F9F9;
      border-radius: 30px;
      padding: 10px 20px;
      border: 1px solid #0090F2;
      width: 200px;
    }

    a.active-link {
      background-color: #0090F2;
      color: #fff;
    }

  }

  .header-navigation-mobile-toggle {
    position: absolute;
    left: 15px;
    top: 20px;
    height: 50px;
    width: 50px;
    background-image: url('../../assets/close-icon-blue.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    cursor: pointer;
  }
}