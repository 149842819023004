.app-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1000px;
  height: 700px;
  @media (max-width: 900px) {
    align-items: flex-start;
    height: auto;
  }
}

section {
  position: relative;
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #fff;
  border-radius: 16px;
  margin: 5px;
  width: calc(50% - 1rem);
  height: 400px;

  @media (max-width: 900px) {
    width: 90%;
    height: 320px;
  }
}

section:first-child {
  background-color: unset;
  .logo {
    width: 100%;
    height: 200px;
    background-image: url("./assets/jumping-man-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    margin-top: 35px;

    @media (max-width: 900px) {
      width: 100%;
      height: 130px;
      background-size: 50%;
      background-position: center;
    }
  }

  p {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    @media (max-width: 900px) {
      text-align: center;
      margin-top: 20px;
    }
  }

}

@media (max-width: 900px) {
  section:first-child {
    height: 250px;
  }
}


section:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .spacer {
    position: relative;
    width: 90%;
    height: 1px;
    border-bottom: 1px solid #D9D9D9;
    margin-top: 30px;
    margin-bottom: 30px;

    div {
      position: relative;
      width: 80px;
      height: 20px;
      background-color: #fff;
      margin: 0 auto;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      margin-top: -7px;
    }
  }

  .app-cta {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 100px;
    cursor: pointer;

    .app-cta-logo {
      position: relative;
      width: 70px;
      height: 70px;
      min-width: 70px;
      min-height: 70px;
      background-color: #0090F2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
      border-radius: 50%;
      transition: 0.3s;
    }

    .camera {
      background-image: url("./assets/camera-icon.svg");
    }

    .trophy {
      background-image: url("./assets/trophy-icon.svg");
      background-size: 46%;
    }

    .app-cta-about {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-left: 30px;
      width: 70%;

      h2 {
        margin: 0;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    &:hover {
      .app-cta-logo {
        background-color: #1b9ff8;
      }
    }
  }
}


section.login {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 90%;
    height: 350px;
    margin-top: 30px;
  }

  .logo-wordmark {
    width: 100%;
    height: 120px;
    background-image: url("./assets/longjump-logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    button {
      margin: 5px;
    }
  }

}

footer {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #979797;
  border-top: 1px solid #D9D9D9;
}
